<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/product' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('product')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="product"
                  ref="product"
                  label-width="150px"
                  class="product"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="product.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="商品名称" prop="productName">
                        <el-input v-model="product.productName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="选择分类" prop="productTypeCode">
                        <el-select
                          v-model="product.productTypeCode"
                          placeholder="请选择"
                          class="wp100"
                        >
                          <el-option
                            v-for="item in productTypeList"
                            :key="item.productTypeCode"
                            :label="item.productTypeName"
                            :value="item.productTypeCode"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="商品说明" prop="productDetail">
                        <el-input v-model="product.productDetail"></el-input>
                      </el-form-item>
                    </el-col>
                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="提示码" prop="promptCode">
                        <el-select
                          v-model="promptCode"
                          placeholder="请选择"
                          class="wp100"
                          value-key="promptCode"
                        >
                          <el-option
                            v-for="item in promptCodeList"
                            :key="item.id"
                            :label="item.promptCode"
                            :value="item"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col> -->
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="包邮" prop="mail">
                        <template>
                          <el-select
                            v-model="product.mail"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in options"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </template>
                      </el-form-item>
                    </el-col>

                     
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="邮费" prop="mailAmount">
                        <el-input v-model="product.mailAmount"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="商品价格" prop="price">
                        <el-input v-model="product.price"></el-input>
                      </el-form-item>
                    </el-col>
                     <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="是否上架" prop="mail">
                        <template>
                          <el-select
                            v-model="product.shelves"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in optionList"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="商品图片" prop="logo">
                        <el-upload
                          class="avatar-uploader"
                          :action="$uploadURL"
                          :show-file-list="false"
                          :on-success="handleAvatarSuccess"
                          :before-upload="beforeAvatarUpload"
                        >
                          <img
                            v-if="product.logo"
                            :src="product.logo"
                            class="avatar"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i> 
                           </el-upload>
                           <span style="font-size:9px;color:red;">商品图片推荐使用1080&nbspx&nbsp810分辨率的图片！</span>
                         </el-form-item>
                         <el-form-item label="商品详情" >
                            <div class="upload flex flex-wrap">
                              <el-upload
                                :action="$uploadURL"
                                list-type="picture-card"
                                :on-preview="previewImg"
                                :on-remove="removeImg"
                                :before-upload="checkFormat"
                                :on-success="uploadImg"
                                :limit="fileLimit"
                                :multiple="true"
                                :file-list="queryInfo.fileList"
                              >
                                <i class="el-icon-plus"></i>
                              </el-upload>
                              <el-dialog :visible.sync="isPreview">
                                <el-image width="100%" :src="previewItem" alt="" />
                              </el-dialog>
                            </div>
                         </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <!-- <ProductFunction></ProductFunction> -->

            <!-- 扩展-->
            <!-- <ProductExtends></ProductExtends> -->
            <div style="margin-left: 40px">
              <div class="f20">规格详情</div>
              <el-button
                type="primary"
                @click="dialogFormVisible = true"
                style="margin-top: 20px"
                >新增
              </el-button>
            </div>
            <el-dialog
              title="新增规格"
              :visible.sync="dialogFormVisible"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              @closed="closed"
            >
              <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                  prop="normName"
                  label="名称"
                  :label-width="formLabelWidth"
                  style="margin-top: 15px"
                >
                  <el-input
                    v-model="form.normName"
                    autocomplete="off"
                    style="width: 300px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  prop="price"
                  label="价格"
                  :label-width="formLabelWidth"
                  style="margin-top: 15px"
                >
                  <el-input
                    v-model="form.price"
                    autocomplete="off"
                    style="width: 300px"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  prop="normContent"
                  label="规格内容"
                  :label-width="formLabelWidth"
                  style="margin-top: 15px"
                >
                  <el-input
                    v-model="form.normContent"
                    autocomplete="off"
                    style="width: 300px"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addNewSpecifications"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="index" prop="num" label="编号" width="180">
              </el-table-column>
              <el-table-column prop="normName" label="名称" width="180">
              </el-table-column>
              <el-table-column prop="price" label="价格" width="180">
              </el-table-column>
              <el-table-column prop="normContent" label="规格内容">
              </el-table-column>
               <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.normName,scope.row.price,scope.row.normContent)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
               @click="del(scope.row.normName)"
              ></i>
            </el-tooltip>
          </template>
                </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ProductFunction from "@/views/product/ProductFunction.vue";
import ProductExtends from "@/views/product/ProductExtends.vue";
export default {
  components: {
    ProductFunction,
    ProductExtends,
  },
  data() {
    return {
      queryInfo: {
        fileList: [],
      },
      dialogFormVisible: false,
      //规格
      tableData: [],
      isPreview: false,
      // 文件上传数量限制
      fileLimit: 9,
      // 文件类型
      fileType: '',
      // 预览的图片
      previewItem: '',
      form: {
        normName: "",
        price: "",
        normContent: "",
      },
      rules: {
        normName: [
          { required: true, message: "请输入规格名称", trigger: "blur" },
        ],
        price: [
          { required: true, message: "请输入规格价格", trigger: "blur" },
          {
            pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/,
            message: "请输入正确的价格（小数点后最多2位）",
            trigger: "blur",
          },
        ],
        normContent: [
          { required: true, message: "请输入规格内容", trigger: "blur" },
        ],
      },
      formLabelWidth: "120px",
      options: [
        {
          value: '1',
          label: "包邮",
        },
        {
          value: '0',
          label: "不包邮",
        },
      ],
      optionList: [
        {
          value: '1',
          label: "上架",
        },
        {
          value: '0',
          label: "下架",
        },
      ],
      newName:"",
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      product: {
        id: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
        productTypeCode: "",
        productTypeName: "",
        productCode: "",
        productName: "",
        logo: "",
        price: "",
        mail: "0",
        mailAmount: "",
        promptCode: "",
        productDetail: "",
        productNormList: [],
        productPictureList:[]
      },
      productType:{},
      productTypeList: [],
      productNormList: [],
      // promptCodeList: [],
    };
  },
  methods: {
     // 判断上传文件类型
    checkFormat(file) {
      const tempList = file.name.split('.')
      const fileType = tempList[tempList.length - 1]
      const imgFile = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      const videoFile = ['mp4', 'rmvb', 'avi', 'flv', 'm2v', 'mkv', 'wmv', 'mp3', 'wav']
      if (!this.fileType) {
        if (imgFile.indexOf(fileType) > -1) {
          this.fileType = 'img'
          this.fileLimit = 4
        } else if (videoFile.indexOf(fileType) > -1) {
          this.fileType = 'video'
          this.fileLimit = 1
        } else {
          this.$message({
            message: '请上传图片或者视频!',
            type: 'warning'
          })
          return false
        }
      } else if (this.fileType === 'img') {
        if (imgFile.indexOf(fileType) === -1) {
          this.$message({
            message: '请上传图片!',
            type: 'warning'
          })
          return false
        }
      }
    },
    // 格式化上传图片源数据类型
    formatUpPic(fileList) {
      this.queryInfo.fileList = fileList.map((v) => {
        return {
          name: v.name,
          url: v.response ? v.response.data.url : v.url,
        };
      });
    },
    // 上传图片
    uploadImg(res, file, fileList) {
      this.formatUpPic(fileList);
      console.log("上传图片",fileList);
    },
    // 预览图片
    previewImg(file) {
      this.isPreview = true;
      this.previewItem = file.url;
      console.log(file);
    },

    // 删除图片
    removeImg(res, file, fileList) {
      console.log(res, file, fileList);
      const index = this.queryInfo.fileList.findIndex((v) => {
        return v.name === res.name;
      });
      this.queryInfo.fileList.splice(index, 1);
    },

    gotoDetail(normName,price,normContent){
      this.dialogFormVisible=true
      this.form.normName=normName;
      this.form.price=price;
       this.form.normContent=normContent;
      this.newName=normName;
    },
 
    //删除规格
    del(normName) {
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
               var newData=[];
      this.tableData.forEach((v)=>{
        if(v.normName!=normName){
              newData.push(v)
        }
      })
      this.tableData=newData;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
           
        })
        .catch(() => {});

      // this.tableData.splice(index, 1);
    },
    closed() {
      this.form.normName = "";
      this.form.price = "";
      this.form.normContent = "";

      this.$refs["form"].clearValidate();
    },
    //添加规格
    addNewSpecifications() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          var list = {};
           if(this.newName!=''&&this.newName!=undefined){
            this.tableData.forEach((v=>{
              if(v.normName==this.newName){
                v.normName=this.form.normName;
                v.price=this.form.price;
                v.normContent = this.form.normContent;
              }
            }))
          this.dialogFormVisible = false;
          }else{
          list.normName = this.form.normName;
          list.price = this.form.price;
          list.normContent = this.form.normContent;
          this.tableData.push(list);
          this.dialogFormVisible = false;
          }
         
        } else {
        }
      });
    },
    //获取规格列表
    getProductNormList() {
      let that = this;
      that.productCode = this.product.productCode;
      that.$http.post("/productNorm/list", that).then(function (response) {
        if (response.data.code == 200) {
          that.productNormList = response.data.data;
          console.log("打印章节列表", that.productNormList);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.product.logo = file.response.data.url;
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error("上传图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    getProductTypeList() {
      var that = this;
      that.$http
        .post("productType/queryList", { parentId: 0 })
        .then(function (response) {
          if (response.data.code == 200) {
            that.productTypeList = response.data.data;
            console.log("productTypeList" + that.productTypeList);
          }
        });
    },
    goIndex() {
      this.$router.push({
        path: "/product",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName){
      var that = this
      var product = this.product
  
      var productTypeCode = that.product.productTypeCode
      that.$http
      .post("productType/queryOne", {productTypeCode:productTypeCode})
      .then(function (response) {
        if (response.data.code == 200) {
          that.product.productTypeName = response.data.data.productTypeName;
          that.queryProductTypeName()
        }
      });
    },
    queryProductTypeName() {
      var that = this;
      var loginName = localStorage.getItem("loginName");
      var abc = JSON.parse(loginName).value;
      this.product.createBy = abc;
      that.product.productNormList = that.tableData;
      that.product.productPictureList = that.queryInfo.fileList;
      that.$http
        .post("/product/save", that.product)
        .then(response => {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            })
             this.goIndex()
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
          
        // .catch(function (error) {
        //   that.$notify.info({
        //     title: "提示",
        //     message: "保存失败",
        //     showClose: true,
        //   });
        // });

    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/product/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.product = response.data.data;
              that.tableData = response.data.data.productNormList;
              that.queryInfo.fileList = response.data.data.productPictureList
              that.productType.productTypeCode = that.product.productTypeCode;
              that.productType.productTypeName = that.product.productTypeName;
              that.promptCode.promptCode = that.product.promptCode;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getProductTypeList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
//上传封面样式
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
